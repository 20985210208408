import { FC, ReactNode, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import { Banner } from '@calm-web/design-system';

import { usePurchase } from '@/hooks/api/usePurchase';
import { calmLogger } from '@/utils/calmLogger';

import messages from './messages';
import { BannerWrapper, WebLink } from './styles';

interface Props {
	partnerId: string;
}

const BillingAddressBanner: FC<Props> = ({ partnerId }) => {
	const history = useHistory();
	const { formatMessage } = useIntl();
	const { getPaymentInfo, paymentInfo, paymentError, loadingPaymentInfo } = usePurchase();

	useEffect(() => {
		getPaymentInfo().catch(err =>
			calmLogger.error('Error in PlanDetails getPaymentInfo within BillingAddressBanner', { partnerId }, err),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const hasBillingAddress = !!paymentInfo.billing_details?.address;
	const handleClick = (): void => {
		history.push({
			pathname: `plan`,
			search: '?updatePayment=true',
		});
	};

	return hasBillingAddress || loadingPaymentInfo || paymentError ? null : (
		<BannerWrapper>
			<Banner backgroundColor="yellow" addMargins data-testid="billing-address-banner">
				{formatMessage(messages.promptForAddress, {
					link: (...chunks: ReactNode[]) => (
						<WebLink data-testid="plan-redirect-link" onClick={handleClick}>
							{chunks}
						</WebLink>
					),
				})}
			</Banner>
		</BannerWrapper>
	);
};

export default BillingAddressBanner;
