import { ReactElement, SetStateAction } from 'react';
import { useIntl } from 'react-intl';

import { PrimaryButton } from '@calm-web/design-system';

import { CARD_IMAGES } from '@/components/ui/Stripe/CreditCardIcons';
import { CalmError } from '@/utils/apiRequest/errors';

import { PaymentInfo, PAYMENT_TYPE } from '../types';
import messages from './messages';
import {
	CardDetail,
	PaymentDetailsContainer,
	BillingAddress,
	AddressDetails,
	DetailsContainer,
	Icon,
	Subtitle,
} from './styles';

interface Props {
	paymentInfo: PaymentInfo;
	paymentError: CalmError | undefined;
	setShowChangeModal: (showChangeModal: SetStateAction<boolean>) => void;
	isSubscriptionExpired: boolean;
}

function PaymentMethodDetails({ paymentInfo }: { paymentInfo: PaymentInfo }): ReactElement | null {
	const { formatMessage } = useIntl();
	const address = paymentInfo.billing_details?.address;
	const billingDetails = address ? (
		<PaymentDetailsContainer>
			<BillingAddress>{formatMessage(messages.billingAddress)}</BillingAddress>
			<AddressDetails data-testid="billing-address-details">
				{formatMessage(messages.addressDetails, {
					linebreak: <br />,
					line1: address.line1,
					line2: address.line2,
					city: address.city,
					state: address.state,
					postal_code: address.postal_code,
				})}
			</AddressDetails>
		</PaymentDetailsContainer>
	) : null;

	if (paymentInfo.type === PAYMENT_TYPE.CARD) {
		const cardImageDetails = CARD_IMAGES[paymentInfo.brand as keyof typeof CARD_IMAGES];
		const brand = paymentInfo.card?.brand ?? paymentInfo.brand;
		const last4 = paymentInfo.card?.last4 ?? paymentInfo.last4;
		const expMonth = paymentInfo.card?.exp_month ?? paymentInfo.exp_month;
		const expYear = paymentInfo.card?.exp_year ?? paymentInfo.exp_year;
		return (
			<>
				<Subtitle>
					{formatMessage(messages.cardName, {
						cardname: brand,
					})}
					{cardImageDetails && <Icon src={cardImageDetails.src} alt={cardImageDetails.alt} />}
				</Subtitle>
				<Subtitle>
					{formatMessage(messages.cardDetails, {
						cardnumber: last4,
					})}
				</Subtitle>

				<PaymentDetailsContainer>
					<CardDetail>
						{formatMessage(messages.cardExp, {
							cardexp: `${expMonth}/${expYear}`,
						})}
					</CardDetail>
				</PaymentDetailsContainer>
				{billingDetails}
			</>
		);
	}

	if (paymentInfo.type === PAYMENT_TYPE.US_BANK_ACCOUNT) {
		return (
			<>
				<Subtitle>
					{formatMessage(messages.bankAccount, {
						bankaccount: paymentInfo.us_bank_account?.last4,
					})}
				</Subtitle>

				<Subtitle>
					{formatMessage(messages.routingNumber, {
						routingnumber: paymentInfo.us_bank_account?.routing_number,
					})}
				</Subtitle>
				{billingDetails}
			</>
		);
	}

	return null;
}

export default function PaymentDetails({
	paymentInfo,
	paymentError,
	setShowChangeModal,
	isSubscriptionExpired,
}: Props): ReactElement {
	const { formatMessage } = useIntl();

	return (
		<DetailsContainer data-testid="payment-details">
			{paymentError && <Subtitle>Error: {paymentError.data.error.code}</Subtitle>}
			{!paymentError && paymentInfo && <PaymentMethodDetails paymentInfo={paymentInfo} />}
			{isSubscriptionExpired ? null : (
				<PrimaryButton
					data-testid="change-payment-details-button"
					onPress={(): void => setShowChangeModal(true)}
				>
					{formatMessage(messages.changeDetailsLink)}
				</PrimaryButton>
			)}
		</DetailsContainer>
	);
}
